Object.defineProperty(exports, "__esModule", {
	value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.getApiMethodType = "GET";
  exports.postApiMethodType = "POST";
  exports.putApiMethodType = "PUT";
  exports.deleteApiMethodType = "DELETE";
  exports.createTicketEndPoint = "bx_block_ticketsystem/tickets";
  
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "TicketSystem";
  exports.labelBodyText = "TicketSystem Body";
  
  exports.btnExampleTitle = "CLICK ME";
  
  exports.screenTitle = "Ticket Form";
  exports.screenSubtitle = "Please describe the issue you are facing";
  
  exports.labelName = "Name*"
  exports.name = "Name"
  exports.labelEmail = "Email*"
  exports.email = "Email"
  exports.labelSubject = "Subject*"
  exports.subject = "Subject"
  exports.labelDescription = "Description*"
  exports.description = "Description"
  
  exports.required = "Required"
  exports.submitBtnText = "Submit";
   
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.validationApiMethodType = "GET";
  exports.sellersAPIEndPoint = "custom_form/seller_accounts";
  
  exports.sellerDetailsAPIMethodPATCH = "PATCH";
  exports.sellerDetailsAPIMethodPUT = "PUT";
  exports.sellerDetailsApiContentType = "application/json";
  exports.sellerDetailsAPIMethodPOST = "POST";
  exports.getSellersAPIMethod = "GET";
  exports.errorMessageShopName = "Shop name is mandatory";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "customform";
  exports.labelBodyText = "customform Body";
  
  exports.btnExampleTitle = "CLICK ME";
  
  exports.congrats = "Congratulations!";
  exports.formSubmitted = "Your form is submitted";
  exports.errorMessageGstin = "GSTIN is mandatory";
  exports.sellerDetails = "Seller Details";
  exports.createTicketType = "Create Ticket Type";
  exports.ticket = "Tickets";
  exports.emptyListText = "This space will display all the created ticket types.  At the moment, it seems like you haven't added any ticket type.Click on the |'Create Ticket Type button'| and begin creating ticket types.";
  exports.issueName = "Issue Name";
  exports.enterIssueName = "Enter Issue Name";
  exports.departments = "Departments";
  exports.selectDepartment = "Select Department";
  exports.create = "Create";
  exports.ticketTitleHeader = "Tickets";
  exports.ticketTypeBtn = "Ticket Type";
  exports.ticketRaiseBtn = "Raise Ticket";
  exports.ticketTitle = "My Tickets";
  exports.departSelect = "Select Department";
  exports.filterSelect = "Filter";
  exports.exportSelect = "Export";
  exports.downloadType = [
	  {
		  id: 1,
		  name: "Excel",
		  text: "excel",
	  },
	  {
		  id: 2,
		  name: "CSV",
		  text: "csv",
	  },
	  {
		  id: 3,
		  name: "PDF",
		  text: "pdf",
	  },
  ];
  exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
  exports.ticketFilterListApiEndPoint = "bx_block_custom_form/tickets/tickets_filter?page=";
  exports.createdBtn = "Created";
  exports.assignedBtn = "Assigned";
  exports.headerList = ["Ticket No", "Ticket Title", "Employee Name", "Role","Issue Date", "Due Date", "Status"];
  exports.assignheaderList = ["Ticket No", "Ticket Title", "Created By","Role", "Issue Date", "Due Date", "Status"];
  exports.content =
	  "This space will display all the tickets you create. At the moment, it seems like you haven't added any tickets Click on the";
  exports.strongContent = "'Raise Ticket'";
  exports.endContent = "button and begin adding tickets.";
  exports.title = "Currently No Tickets Found";
  exports.backtoHome = "Go to home";
  exports.statusListApiEndPoint = "bx_block_custom_form/tickets/status_list";
  exports.clearAll = "clear all filter";
  exports.viewFeedbackBtn = "View Feedback";
  exports.addFeedbackBtn = "Add Feedback";
  exports.issueName= "Issue Name";
  exports.selectedDept= "Departments";
  exports.edit= "Edit";
  exports.duplicate= "Duplicate";
  exports.delete= "Delete";
  exports.cancel= "Cancel";
  exports.save= "Save";
  exports.deleteMethodType = "DELETE";
  exports.ticketListApiEndPoint = "bx_block_custom_form/tickets";
  exports.confirmTicket = "from ticket list";
  exports.recentlyCreatedText ="Recent Created Tickets"
  exports.recentlyAssignedText ="Recent Assigned Tickets"
  exports.ticketTypeApiEndPoint = "bx_block_custom_form/ticket_types";
  exports.prorityText = "Priority";
  exports.priorityPlaceholder = "Select Priority";
  exports.noPriorityMessage = "No Priority Found"
  exports.prorityErrorMessage = "Please select Priority";
  exports.ticketTypePlaceholder = "Select Ticket Type";
  exports.noTicketMessage = "No Ticket Found";
  exports.createBranchApiEndpoint = "bx_block_branch/branches";
  exports.ticketTypeText ="Ticket Type";
  exports.branchText = "Branch";
  exports.branchPlaceholder = "Select Branch";
  exports.noBranchMessage = "No Branch Found";
  exports.noDepartmentMessage = "No Department Found";
  exports.viewAttachmentText = "View Attachment";
  exports.deparmentListApiEndPoint = "bx_block_departments/common_departments?";
  exports.titleText = "Fill in Details";
  exports.titleFieldText = "Title";
  exports.descriptionText = "Description";
  exports.dueDateText = "Due Date";
  exports.attachmentTitle = "Attachment";
  exports.attachFileText= "Attach File";
  exports.departmentText = "Department";
  exports.employeeText = "Employee";
  exports.cancelBtnText = "Cancel";
  exports.createBtnText = "Create";
  exports.employeePlaceholder = "Select Employee";
  exports.noEmployeeMessage = "No Employee Found";
  exports.employeeListApiEndPoint = "account_block/accounts/employees_by_branch_and_department?";
  exports.departmentErrorMessage = "No departments match the selected criteria. Please either change the ticket type or add the appropriate department to the branch";
  exports.employeeErrorMessage = "No employees are found in this branch of department";
  exports.successfullyTicketCreatedMessage = "Ticket has been created successfully"
  exports.selectAll = "Select All";
  exports.greatTitle = "Great!";
  exports.confirmMessage = "Ticket has been created successfully!";
  exports.selectedDepartmentApiEndPoint = "bx_block_departments/selected_departments"
  exports.okBtnText = "Go to ticket listing";
  exports.allTicketsListApiEndPoint = "bx_block_custom_form/tickets/tickets_filter?download=true";
  exports.dateFormat = "DD/MM/YYYY";
  
  exports.characterLengthText = "Maxmimum 500 characters";
  exports.fileSizeText = "Maximum file size 5 MB";
  exports.noSearchResult = "No search result found for";
  exports.fileSizeError = "Maximum file size should be 5MB";
  exports.ticketDetails = "Ticket Details";
  exports.ticketNumber = "Ticket Number";
  exports.ticketTitle = "Ticket Title";
  exports.issueDate = "Issue Date";
  exports.dueDate = "Due Date";
  exports.ticketDescription = "Ticket Description";
  exports.assignEmployee = "Assigned to employee";
  exports.attachments = "Attachments";
  exports.comment = "Comment";
  exports.comments = "Comments";
  exports.rating = "Add Rating";
  exports.feedback = "Feedback";
  exports.allComment = "All Comments";
  exports.fromComment = "from comments";
  exports.ticketUpdateMessage = "Ticket has been updated successfully";
  exports.placeholderText = "Add text here";
  exports.creationDate = "Enter the date of creating of the company";
  exports.noTicketType = "No Ticket Type Found";
  exports.commentApiEndPoint = "bx_block_custom_form/comments"
  exports.editCommentSuccsessMessage = "Comment has been edited successfully"
  exports.commentDeletedMessage = "Comment has been deleted successfully"
  exports.viewAllCommentsText = "View All Comments";
  exports.searchTicketTypeEndPoint = "bx_block_custom_form/search?";
  exports.ticketTypeList = "from ticket type list"
  // Customizable Area End
