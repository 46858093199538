Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CarouselDisplay";
exports.labelBodyText = "CarouselDisplay Body";
exports.btnExampleTitle = "CLICK ME";
exports.navTitle = "Choose & Edit Template";
exports.headerTitle = "Create Procedure";
exports.productHeaderTitle = "Product Catalogue";
exports.CarouselImagesApiEndpoint = "bx_block_cftabletview/procedures/get_procedure_templates";
exports.ProductCarouselTemplateApiEndpoint = "bx_block_cftemplatebuilder6/product_infos/get_product_catalogue_templates";
exports.MenuCarouselTemplateApiEndpoint = "bx_block_cftemplatebuilder6/menus/get_menu_templates";
exports.allText = "All";
exports.withImage = "With Images";
exports.withoutImage = "Without Images"

//Menu Carousel
exports.menuHeaderTitle = "Create Menu";
exports.MenuCarouselTemplateApiEndpoint = "bx_block_cftemplatebuilder6/menus/get_menu_templates";

// Customizable Area End