Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.placeHolderConfirmPassword = "Confirm Password";
exports.placeHolderName = "Name";
exports.placeHolderPhoneNumber = "Phone Number";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelConfirmPassword = "Confirm Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
	"Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.titleTextSignUp = "Sign Up";
exports.signUpBtn = "Sign Up";
exports.signupLoginBtn = "Log In";
exports.alreadyHaveAnAccountText = "Already have an account? ";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";
exports.JoiningFormEndpoint ="bx_block_profile/profiles";
exports.CreateUserProfileEndPoint ="bx_block_profile/profiles";
exports.Companyuserdetails ="account_block/accounts/get_business_owner"
exports.apiMethodTypeAddDetail = "POST";
exports.putMethod = "PUT";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.getBusinessOwnerDatContentType = "application/json";
exports.getBusinessOwnerDataEndPoint = "account_block/accounts/get_business_owner";
exports.registerBusinessOwnerEndPoint = "account_block/accounts";
exports.registerOtherUserEndPoint = "account_block/accounts/update_employee_password";
exports.getBusinessOwnerDataMethodType = "GET";
exports.countryCodeApiEndPoint = "account_block/country_code_and_flags/countries_with_phone_formats";
exports.countryCodePlaceholder = "Code";
exports.noCountryCode = "No data";

exports.industryPlaceholder = "Select Industry";
exports.businessTypePlaceholder = "Select Business Type";

exports.joinButton = "Join";
exports.nextButton = "Next";
exports.backButton = "Back";

exports.titleText = "Bridging Gaps";
exports.landingDescriptionText = "Everyone and everything in one place. A space for team to collaborate,organize and over achieve.";
exports.getStartedBtn = "Get Started";
exports.companyName = "Company Name";
exports.familyName = "Family Name";
exports.uploadFile = "Upload your";
exports.profilePic = "Profile picture";
exports.dateOfBirth = "Date of Birth";
exports.joinFormText = "Joining Form";
exports.emailPlaceholder = "Add your email";
exports.phoneNumberPlaceholder = "Add your phone number";
exports.dobPlaceholder = "00-00-0000";
exports.fullNamePlaceholder = "Add your full name";
exports.familyNamePlaceholder = "Add your family name";
exports.fileSizeError = "Maximum file size should be less than 5mb";
exports.fileSelected = "File selected is not in JPEG / JPG / PNG format";
exports.joiningSuccessMessage = "JoiningForm has been saved successfully"

exports.profileHeader = "My Profile";
exports.uploadImageText = "Upload your Profile picture";
exports.invalidImageFormate = "File selected is not in JPG / PNG format"
exports.addEditProfileSuccessMsg = "Profile has been saved successfully"
exports.profileNameLabel = "Name"
exports.profilePhoneLabel = "Phone Number"
exports.profilePersonalEmailLabel = "Personal Email"
exports.profileNextBtn = "Next"
exports.landingPageUserNotFound = "User not found"
exports.fileErrorMsg = "File type not supported";
exports.supportedFileTypes = ["application/pdf","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
exports.getIndustriesApiEndPoint = "bx_block_profile/get_industries";
exports.getBusinessTypesApiEndPoint = "bx_block_profile/get_business_types";
exports.profileUpdatedSuccess = "Company profile has been saved successfully";
exports.companyProfileApiEndPoint = "bx_block_profile/companies";
exports.companyLogoText = "Upload Company Logo";
exports.uploadText = "upload in Jpg, png formats only";
exports.ownerEmailText = "Owner Email ID";
exports.fullName = "Full Name";
exports.industryText = "Industry";
exports.businessTypeText = "Type of Business";
exports.creationDate = "Creation Date";
exports.creationDatePlaceholderText = "Enter the date of creating of the company";
exports.noOfEmployeeText = "No of Employees";
exports.employeeWorkingPlaceholderText = "Enter number of employees working";
exports.workingHoursText = "Working Hours";
exports.fromPlaceholderText = "From";
exports.toWorkingPlaceholderText = "To";
exports.headOfficeText = "Head Office";
exports.headOfficePlaceholderText = "Enter the address of your head office location";
exports.descriptionText = "Description";
exports.descriptionPlaceholderText = "Write a few things about your company";
exports.documentText = "Documents";
exports.uploadDocumentsText = "Upload Documents";
exports.myCompanyProfileText = " My Company Profile"

exports.agreenTermAndConditionsSuccessMsg = "Congratulations! You have successfully signed up"
exports.getTermEndpoint ="bx_block_admin/terms_and_conditions"
exports.agreeTermEndpoint ="account_block/agree_disagree_terms"
exports.downloadTermEndpoint ="bx_block_admin/terms_and_conditions/download_pdf"
exports.errorMessage = "Please complete your profile first"
exports.deleteAPiMethod = "DELETE";
exports.logoutApiEndpoint = "bx_block_logout/logout";

// Customizable Area End
