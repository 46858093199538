Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskList";
exports.labelBodyText = "TaskList Body";

exports.btnExampleTitle = "CLICK ME";

exports.sellerDetails = "Seller Details";
exports.createTicketType = "Create Ticket Type";
exports.ticket = "Tickets";
exports.emptyListText = "This space will display all the created ticket types.  At the moment, it seems like you haven't added any ticket type.Click on the |'Create Ticket Type button'| and begin creating ticket types.";
exports.issueName = "Issue Name";
exports.enterIssueName = "Enter Issue Name";
exports.departments = "Departments";
exports.selectDepartment = "Select Department";
exports.create = "Create";
exports.taskTitleCreated = "Create Task";
exports.taskTitleAssign = "Assigned Task";
exports.taskTitleManage = "Manage Task";
exports.ticketTypeBtn = "Ticket Type";
exports.departSelect = "Select Department";
exports.filterSelect = "Filter";
exports.exportSelect = "Export";
exports.downloadType = [
	{
		id: 1,
		name: "Excel",
		text: "excel",
	},
	{
		id: 2,
		name: "CSV",
		text: "csv",
	},
	{
		id: 3,
		name: "PDF",
		text: "pdf",
	},
];
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.ticketFilterListApiEndPoint = "bx_block_tasks/tasks/get_tasks?page=";
exports.createdTaskBtn = "Created Task";
exports.assignedTaskBtn = "Assigned Task";
exports.headerList = ["Task ID", "Task Title", "Employee Name", "Role", "Assign Date", "Due Date", "Status"];
exports.assignheaderList = ["Ticket No", "Ticket Title", "Created By", "Issue Date", "Due Date", "Status"];
exports.content = "This space will display all the task you create. At the moment, it seems like you haven't added any tasks Click on the";
exports.noAssignedContent = "This space will display all the task assigned to you. At the moment, it seems like you haven't been assigned any tasks.";
exports.strongContent = "'Create Task'";
exports.endContent = "button and begin adding task.";
exports.title = "Currently No Task Found";
exports.backtoHome = "Go to home";
exports.statusListApiEndPoint = "bx_block_tasks/tasks/status_list";
exports.clearAll = "clear all filter";
exports.delete= "Delete";
exports.cancel= "Cancel";
exports.save= "Save";
exports.deleteMethodType = "DELETE";
exports.taskListApiEndPoint = "bx_block_tasks/tasks";
exports.confirmTask = "from task list";
exports.allTaskListApiEndPoint = "bx_block_tasks/tasks/get_tasks?download=true";
exports.myTaskText = "My Task";
exports.dateFormat = "DD/MM/YYYY";
exports.selectedDepartApiEndpoint = "bx_block_departments/selected_departments";
exports.noSearchResult = "No search result found for"
exports.inReview = "In Review"
// Customizable Area End