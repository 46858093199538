Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.updateApiMethodType = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrganisationHierarchy";
exports.labelBodyText = "OrganisationHierarchy Body";

exports.btnExampleTitle = "CLICK ME";
exports.pointOfSaleText = "Type of Point of Sale";
exports.textPlaceHolder = "Enter here";
exports.locationText = "Location of Point of Sale";
exports.location = "Location"
exports.timeZoneText ="Time Zone";
exports.hoursText = "Working Hours";
exports.phoneNumberText = "Phone Number";
exports.emailText = "Email";
exports.textareaPlaceHolder = " Add text here";
exports.textDescription = "Description";
exports.textInfo = "Important Information";
exports.submitBtnText = "Submit"
exports.createBranchApiEndpoint = "bx_block_branch/branches";
exports.timezoneApiEndpoint = "bx_block_branch/branches/time_zones";
exports.toastSuccessMessage = "Form submitted successfully!";
exports.invalidMessage = "Invalid Data!!";
exports.branchNameText = "Branch Name";
exports.astrikText = "*";
exports.timeZoneErrorMessage = "Please Select the location";
exports.timeZoneTitle = "Time zone";
exports.openingErrorMessage = "Please select opening time";
exports.closingErrorMessage = "Please select closing time";
exports.openingTitle = "From";
exports.closingTitle = "To";
exports.existingTitle = "Existing Branch List";
exports.wrapperTitle = "Existing Branches";
exports.btnTitle = "Add New Branch";
exports.deleteMethodType = "DELETE";
exports.errorMessage = "Something went wrong !!!";
exports.editButton = "Edit";
exports.deleteButton = "Delete";
exports.listText = "No Branch to show";
exports.departmentButton = "View Departments";
exports.backtoHome = "Go to home";
exports.editTitle = "Edit New Branch";
exports.successMessage = "Branch list updated successfully";
exports.imageValidate = "Invalid file type. Please select a JPEG, PNG, or GIF image."
exports.confirmBranch = "from branch list";
exports.companyProfileText = "Company Profile Details";
exports.editCompanyProfileText = "Edit Company Profile";
exports.companyNameText = "Company Name";
exports.ownerNameText = "Owner Name";
exports.ownerEmailId = "Owner Email ID";
exports.ownerPhoneNumber = "Phone Number";
exports.industryText = "Industry";
exports.typeOfBusinessText = "Type of Business";
exports.creationData = "Creation Date";
exports.noOfEmployeeText = "No of employees";
exports.headOfficeText = "Head office";
exports.companyProfileApiEndPoint = "bx_block_profile/get_company_profile";
exports.companyDoc = "Company Docs";
exports.recentlyUploadedDoc = "Uploaded Company Documents";
exports.uploadDocuments = "Upload Documents";
exports.uploadDocumentIcon = "Upload Document Icon";
exports.uploadDocText = "(Supported Documents in Docs & PDF  formats only)";
exports.cancel = "Cancel";
exports.upload = "Upload";
exports.download = "Download";
exports.saveButtonText = "Save Changes";
exports.industryApiEndPoint = "bx_block_profile/get_industries";
exports.businessTypeApiEndPoint = "bx_block_profile/get_business_types";
exports.companyUpdateApiEndPoint = "bx_block_profile/companies";
exports.updateSuccessMessage = "Company profile has been updated successfully";
exports.typeOfBusinessPlaceholder = "Select type of business";
exports.industryPlaceholder = "Select Industry";
exports.countryCodeApiEndPoint = "account_block/country_code_and_flags/countries_with_phone_formats";
exports.countryCodePlaceholder = "Code";
exports.noCountryCode = "No data";
exports.noSelectedIndustry = "Industry is not selected";
exports.noSelectedBusiness = "Business type is not selected";
exports.noSearchResult = "No search result found for"
exports.noDocumentListTxt = "No Document to show please upload document"
exports.branchHeaderData = ["Branch Name", "Point of Sale", "Location", "Action","Department"];
exports.otherUserBranchHeaderData = ["Branch Name", "Point of Sale", "Location"];
exports.greatTitle = "Great!";
exports.confirmMessage = "Changes Saved Successfully.";
exports.okBtnText = "Go to listing"
exports.noTimeZoneMessage = "No Time zone found";
exports.title = "Currently No Branch Found";
exports.content = "This space will display all the branches you create. At the moment, it seems like you haven't added any branch Click on the";
exports.strongContent = "'Add New Branch'";
exports.endContent = "button and begin creating branch.";
exports.branch = "Branch";
exports.selectbranch = "Select Branch";
exports.role = "Role";
exports.selectRole = "Select Role";
exports.department = "Department";
exports.selectDept = "Select Department";
exports.supervisor = "Supervisor";
exports.selectSupervisor = "Select Supervisor";
exports.manager = "Manager";
exports.sendInvite = "Send Invite"
exports.employeeListingApiMethodType = "POST";
exports.headerList = ["Task ID", "Task Title", "Employee Name", "Assign Date", "Due Date", "Status"];
exports.departSelect = "Select Department"
exports.filterSelect = "Filter";
exports.downloadType = [
	{
		id: 1,
		name: "Excel",
		text: "excel",
	},
	{
		id: 2,
		name: "PDF",
		text: "pdf",
	},
];
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.editEmployeeListingApiMethodType = "PUT";
exports.getEmployeeDetailsApiMethodType = "GET";
exports.getEmployeeDetailsApiEndpoint= "account_block/accounts/get_employee_data";
exports.employeeSuccessMessage = "Employee data updated successfully";
exports.searchResult = "No search result found for";
exports.status = "Status";
exports.editErrorMessage = "You are not authorized to perform this action.";
exports.roleSelectErrorMessage = "This action can be performed only if the employee status is Pending.";
exports.deleteClickErrorMessage = "This action can be performed only if the employee status is Pending or Inactive.";
exports.employeeTitle = "View Employee";
exports.departmentText = "Departments";
exports.existingDepartText = "Existing Departments";
exports.manageDepartText = "Manage Departments";
exports.goToDepartment = "Go to Manage Departments, and choose appropriate departments.";
exports.inviteEmployeeText = "Invite Employee";
exports.selectedDepartText = "Selected Department";
exports.branchErrorMessage = "No branches found. Please add a branch first";
exports.deprtMessage = "No Department Found";
exports.enterTextEmail = "Enter Email";
exports.invitationSendMessage = "Your Invitation has been successfully sent."
exports.goToHome = "Go to Home";
exports.getUserDetailApiEndpoint = "account_block/accounts/get_business_owner";
exports.confirmDoc = "from Docs list";
exports.docDeleteEndPoint = "bx_block_profile/delete_company_document";
exports.serachDocEndPoint = "bx_block_advanced_search/search_company_documents?name=";
exports.getCompanyDocApiEndPoint = "bx_block_profile/company_documents";
exports.uploadDocApiEndPoint = "bx_block_profile/add_company_document";
exports.uploadSuccessMsg = "File Uploaded Successfully";
exports.fileSizeErrorMsg = "File size too large, please select a file less than 5mb";
exports.fileErrorMsg = "File type not supported";
exports.docUploadedMsg = "Docs Successfully Uploaded";
exports.docDeleteMsg = "Docs Deleted Successfully"

//Company hierarchy
exports.headerTitle = "Hierarchy"
exports.companyHierarchyTitle = "Company Hierarchy"
exports.getHierarchyEndpoint = "account_block/accounts/get_child_users"
exports.errorDepartMessage = "Please select atleast one department"
exports.chooseDepartmentApiEndPoint = "bx_block_departments/choose_departments"
exports.departSuccessSelectMessage = "Departments have been saved successfully"
exports.selectedDepartmentApiEndPoint = "bx_block_departments/selected_departments"
exports.departmentApiEndPoint = "bx_block_departments/get_departments"
exports.addDepartText = "Add Departments";
exports.paraDepartText = "Lorem ipsum dolor sit amet. Et rerum perferendis et quia enim id cumque Quis vel";
exports.skipButton = "Skip";
exports.nextButton = "Next"
exports.noBranchMessage = "No Branch Found";
exports.departmentErrorMessage = "No departments found to the selected criteria. Please add the appropriate department to the branch";
exports.addManagerText = "Please Add Manager"
exports.noSupervioserText = "No Supervisor To Show"
exports.superParent = "super_parent_id"
// Customizable Area End