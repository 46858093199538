Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully'
exports.tablist = ["Procedures", "Product Info", "Forms", "Checklists"];
exports.filterByData = "Filter By Date";
exports.today = "Today";
exports.yesterday = "Yesterday";
exports.lastDays = "Last 15 days";
exports.filterMenuList = ["Today","Yesterday","Last 15 days"];
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.checkListApiEndPoint = "bx_block_multipageforms2/checklists/";
exports.deletecheckListApiEndPoint = "bx_block_multipageforms2/checklists/";
exports.getProcedureListApiEndPoint= "bx_block_cftabletview/procedures?favorite=true&";
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.productTemplateListApiEndPoint= "bx_block_cftemplatebuilder6/product_infos/?";
exports.productApiEndPoint= "bx_block_cftemplatebuilder6/product_infos/";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.noDepartmentMessage = "No Department Found";
exports.departSelect = "Select Department";
exports.date = "Date-";
exports.delete = "Delete";
exports.duplicate = "Duplicate";
exports.edit = "Edit";
exports.favouriteMark = "Mark as Favourite";
exports.favouriteUnmark = "Unmark as Favourite";
exports.deleteProcedureApiEndPoint = "bx_block_cftabletview/procedures/"
exports.departSelect = "Select Department";
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.headerList = ["Procedures", "Procedure Name", "", "Date", "Action", "Favourite"];
exports.checkListHeaderList = ["Checklists", "Checklist Name", "", "Date", "Action", "Favourite"];
exports.formHeaderList = ["Forms", "Form Name", "", "Date", "Action", "Favourite"];
exports.menuHeaderList = ["Menu Templates", "Menu Template Name", "Template Type", "Date", "Action", "Favourite"];
exports.title = "Currently No Procedure Found";
exports.favtitle = "Favourite";
exports.from = "From";
exports.to = "To";
exports.favouriteProcedureApiEndPoint = "bx_block_cftabletview/procedures/";
exports.clearAllFilters = "Clear All Filter";
exports.procedureTemplateNavigation = "/Template";
exports.deleteModalTxt = ["Are you sure you want to delete ","This Procedure Template"," from the library?"]
exports.yes = "yes";
exports.no = "no";
exports.templateDeleteTitleProcedure = "Procedure";
exports.procedureFrom = "from library";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.checklistDeleteTitle = "Checklist";
exports.checklistFrom = "from library";
exports.checklistNoDataTxt = "Currently No Checklist Found";
exports.FormApiEndPoint = "bx_block_multipageforms2/forms/";
exports.deleteFormApiEndPoint = "bx_block_multipageforms2/forms/";
exports.formDeleteTitle = "Form";
exports.formNoDataTxt = "Currently No Form Found";
exports.departSelect = "Select Department";
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.headerList = ["Product Info", "Product Info Name", "Template Type", "Date", "Action", "Favourite"];
exports.titleText = "Currently No Product Template Found";
exports.noMenuText = "Currently No Menu Template Found";
exports.libraryTitle = "My Library";
exports.today = "Today";
exports.yesterday = "Yesterday";
exports.lastDays = "Last 15 days";
exports.TemplateNavigation = "/ProductTemplate";
exports.templateDeleteTitle = "Product Template";
exports.productFrom = "from library";
exports.favouriteFrom = "from favourite";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.searchResult = "No search result found for";
exports.departError = "Please Add Department";
exports.deleteSuccessMessage = "Product info has been deleted successfully"

exports.menuTemplateListApiEndPoint= "bx_block_cftemplatebuilder6/menus/?";
exports.menuApiEndPoint= "bx_block_cftemplatebuilder6/menus/";

exports.menuFrom = "from the menus";
exports.menuTemplateDeleteTitle = "Menu Template";
exports.menuTemplateNavigation = "/MenuTemplate";

exports.noMenuText = "Currently No Menu Template Found";

exports.productTemplateText = "Product Info Templates";
exports.menuTemplateText = "Menu Templates"

exports.deleteMenuSuccessMessage = "Menu has been deleted successfully"
exports.fromLibrary = "from library";
exports.fill = "Fill";

exports.procedureDeleteMessage = "Procedure has been deleted successfully";
exports.checklistDeleteMessage = "Checklist has been deleted successfully";
exports.formDeleteMessage = "Form has been deleted successfully"
exports.noSearchResult = "No search result found for"

// Customizable Area End
