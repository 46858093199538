Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";

exports.btnExampleTitle = "CLICK ME";

exports.edit= "Edit";
exports.duplicate= "Duplicate";
exports.delete= "Delete";
exports.cancel= "Cancel";
exports.save= "Save";

exports.taskDetails= "Task Details";
exports.taskID= "Task ID";
exports.taskTitle= "Task Title";
exports.assignedDate= "Assigned Date";
exports.dueDate= "Due Date";

exports.taskTitleName = "title";
exports.taskDescriptionName = "description";
exports.taskPlaceholder = "Add text here";

exports.taskDueDateName = "due_date";
exports.taskDueDatePlaceholder = "Select";

exports.taskBranchLabel = "Branch";
exports.taskBranchName = "branch_id";
exports.taskBranchPlaceholder = "Select Branch";
exports.taskNoBranchMessage = "No Branch Found"

exports.taskDepartmentLabel = "Department";
exports.taskDepartmentName = "department_id";
exports.taskDepartmentPlaceholder = "Select Department";
exports.taskNoDepartmentMessage = "No Department Found"

exports.taskPriorityLabel = "Priority";
exports.taskPriorityName = "priority";
exports.taskPriorityPlaceholder = "Select Priority";
exports.taskNoPriorityMessage = "No Priority Found";

exports.taskEmployeeLabel = "Assigned to employee";
exports.taskAssignedByLabel = "Assigned by";
exports.taskEmployeeName = "assignee_ids";
exports.taskEmployeePlaceholder = "Select Employee";
exports.taskNoEmployeeMessage = "No Employee Found";

exports.taskAttachmentLabel = "Attachments";
exports.taskAttachmentName = "attachment";
exports.taskDocumentName = "document";
exports.attachFileText= "Attach File";

exports.taskCommentLabel = "Comments";
exports.taskCommentName = "comment";
exports.taskCommentPlaceholder = "Add Comment.";
exports.taskAllComments = "Comments";
exports.taskViewAllComments = "View All Comments";
exports.taskFromComment = "from comments";

exports.taskRatingLabel = "Rating";
exports.taskRatingName = "rating";

exports.taskFeedbackLabel = "Feedback";
exports.taskFeedbackName = "feedback";

exports.taskSuccessMessage = "Task has been updated successfully";


exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.getBranchesUrl = "bx_block_branch/branches";
exports.getStatusUrl = "bx_block_tasks/tasks/status_list";
exports.getDepartmentsUrl = "bx_block_departments/selected_departments/";
exports.getEmployeesUrl = "account_block/accounts/employees_by_branch_and_department";
exports.taskUrl = "bx_block_tasks/tasks";
exports.taskCommentUrl = "bx_block_custom_form/comments";

exports.taskDepartmentErrorMessage = "No departments found to the selected criteria. Please add the appropriate department to the branch";

exports.createTaskTitle = "Create Task";
exports.descPlaceholder = "Add text here";
exports.viewAttachmentText = "View Attachment";
exports.fileTypeErrorMessage = "File type should be PDF, JPEG, or PNG, and maximum file size should be 5MB" ;
exports.textBranch = "Branch"
exports.createBranchApiEndpoint = "bx_block_branch/branches";
exports.deparmentDataApiEndPoint = "bx_block_departments/selected_departments";
exports.departmentErrorMessage = "No departments match the selected criteria. Please either change the branch or add the appropriate department to the branch";
exports.branchPlaceholderText = "Select Branch";
exports.noBranchText = "No Branch Found";
exports.noDepartMessage = "No Department Found";
exports.departmentSelect = "Select Department";
exports.employeeErrorMessage = "No employees are found in this branch of department";
exports.employeeListApiEndPoint = "account_block/accounts/employees_by_branch_and_department?";
exports.characterLengthText = "Maxmimum 500 characters";
exports.fileSizeText = "Maximum file size 5 MB";
exports.createBtnText = "Create";
exports.employeePlaceholder = "Select Employee";
exports.noEmployeeMessage = "No Employee Found";
exports.titleFieldText = "Title";
exports.descriptionText = "Description";
exports.dueDateText = "Due Date";
exports.attachmentTitle = "Attachment";
exports.attachFileText= "Attach File";
exports.departmentText = "Department";
exports.employeeText = "Employee";
exports.cancelBtnText = "Cancel";
exports.createBtnText = "Create";
exports.prorityText = "Priority";
exports.priorityPlaceholder = "Select Priority";
exports.noPriorityMessage = "No Priority Found";
exports.createTaskApiEndPoint = "bx_block_tasks/tasks";
exports.greatTitle = "Great!";
exports.confirmMessage = "Task has been created successfully";
exports.okBtnText = "Go to task listing"
exports.noProcedureTxt = "This procedure has been deleted";
exports.approvalTxt = "Approve this task?";
exports.fileSizeError = "Maximum file size should be 5MB";
exports.creationDate = "Enter the date of creating of the company";
exports.attachmentPlaceholder = "Upload in jpeg, png, PDF format only";
exports.acceptImageType = "image/jpeg,application/pdf,image/png";
exports.branchError = "No branches found. Please add a branch first";
exports.commentDeletedMessage = "Comment has been deleted successfully"
exports.commentEditedMessage = "Comment has been edited successfully";
exports.taskDescription = "Task Description"
// Customizable Area End