Object.defineProperty(exports, "__esModule", {
	value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";

exports.pdfContentError = "No content found"
exports.employeeHeaderData = ["Full Name", "Email","Role", "Phone Number", "Joining Date", "Status", "Location"];
exports.getEmployeeListingApiEndpoint = "account_block/accounts/get_employees_list?page=";
exports.getAllEmployeeListingApiEndpoint = "account_block/accounts/get_employees_list?download=true";
exports.selectedDepartmentApiEndpoint = "bx_block_departments/selected_departments";
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.geteditEmployeeListingApiEndpoint = "account_block/accounts/edit_employee";
exports.errorMessage = "Something went wrong !!!";
exports.headTitle = "Employee Directory";

exports.roleType = [
	{
		id: 3,
		name: "Manager",
		text: "manager",
	},
	{
		id: 4,
		name: "Supervisor",
		text: "supervisor",
	},
	{
		id: 5,
		name: "Agent",
		text: "agent",
	},
];
exports.departSelect = "Select Department";
exports.exportTitle = "Export";
exports.downloadType = [
	{
		id: 1,
		name: "Excel",
		text: "excel",
	},
	{
		id: 2,
		name: "CSV",
		text: "csv",
	},
	{
		id: 3,
		name: "PDF",
		text: "pdf",
	},
];
exports.searchResult = "No search result found for";
exports.titleNoEmployee = "Currently No Employee Found";
exports.contentText =`This space will display all the employee. At the moment, it seems like you haven't added any employee`;
exports.editButton = "Edit";
exports.genderText = "Gender";
exports.department = "Department";
exports.departMessage = "Please select at least one department";
exports.status = "Status";
exports.statusText = "Select Status";
exports.selectRoleText = "Select Role";
exports.genderPlaceholderText = "Select Gender";
exports.reportingUser = "Select Reporting To";
exports.noDataMessage = "List not found"
exports.role = "Role";
exports.roleTitle = "Role by";
exports.location = "Location";
exports.teamMemberText = "Team Member";
exports.saveButtonText = "Save Changes";
exports.confirmMessage = "Changes Saved Successfully.";
exports.okButtonText = "Go to Home";
exports.roleSelectErrorMessage = "This action can be performed only if the employee status is Pending.";
exports.deleteClickErrorMessage = "This action can be performed only if the employee status is Pending or Inactive.";
exports.editErrorMessage = "You are not authorized to perform this action.";
exports.deleteEmployeeEndpoint = "account_block/accounts/delete_employee";
exports.employeeListApiEndpoint = "account_block/accounts/get_employees_list?invited=true&department_id=";

exports.roleSuccessMessage = "Role updated Successfully.";
exports.employeeTitle = "View Employee";

exports.invitedEmployee = "Invited Employees";
exports.departEmployeeHeaderData = ["Department", "Employee Name", "Status", "Role", "Action"];
exports.deleteButton = "Delete";
exports.confirmList = "from the list";
exports.editRoleText = "Edit Employee Role";
exports.cancel = "Cancel";
exports.clearAll = "Clear All Filter";
exports.employeeDetailTitle = "Employee Profile Details";
exports.position = "Position";
exports.departmentText = "Department";
exports.overview = "Overview";
exports.work = "Work";
exports.personal = "Personal";
exports.basic_info = "Basic Info";
exports.docsText = "Documents";
exports.my_docs = "My Docs";
exports.shared_docs = "Uploaded Docs";
exports.joiningDate = "Joining Date";
exports.reportingTo = "Reporting To";
exports.save = "Save";
exports.fullName = "Full Name";
exports.emailPlaceholderText = "Enter Email"
exports.phoneNumber = "Phone Number";
exports.email = "Email";
exports.branch = "Branch"
exports.address = "Address";
exports.countryErrorMessage = "No countries available"
exports.countryCodePlaceholder = "Code";
exports.noCountryCodeMessage = "No data";
exports.phoneNumberPlaceHolder= "Add your phone number";
exports.getCountryListEndpoint= "account_block/country_code_and_flags/countries_with_phone_formats";
exports.dob = "DOB";
exports.country = "Country";
exports.drivingLicense = "Driving License";
exports.national_id = "National ID";
exports.passport = "Passport";
exports.schoolDegree = "School Degree";
exports.universityDegree = "University Degree";
exports.vaccineCert = "Vaccination Certificate";
exports.cert = "Certificate";
exports.locationPlaceholder = "Enter the Location";
exports.countryPlaceHolder= "Select Country";
exports.noCountryMessage= "No country Found";
exports.attachmentPlaceholder = "Upload in jpeg, png, PDF format only";
exports.acceptImageType = "image/jpeg,application/pdf,image/png";
exports.imageSize = "Maximum file size 5 MB";
exports.tenure = "Tenure";
exports.years = "years";
exports.tenurePlaceholder = "Enter the tenure";
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.fileSizeErrorMsg = "File size too large, please select a file less than 5mb";
exports.paragraph = "Contrary to popular belief";
exports.noDocs = "No Douments Found";
exports.myDocsText ="My Documents";
exports.uploadedText = "Uploaded Documents";
exports.documentList = "from uploaded documents";
exports.deleteMessage = "Documents Deleted Successfully.";
exports.getProfileDetailsApiEndPoint = "bx_block_profile/profile/";
exports.updateProfileApiEndPoint = "bx_block_profile/profiles"
exports.profileSuccessMessage = "Profile details updated successfully.";
exports.employeeListEndPoint = "account_block/accounts/get_employees_list";
exports.branchName = "Branch Name";
exports.genderErrorText = "Please select gender";
exports.reportingToApiEndPoint = "account_block/accounts/reporting_to?status=active";
exports.newReporting = "Replace User"
exports.newReportingApiEndPoint = "account_block/accounts/branch_users?status=active"
exports.newReportingText = "Select new user"
exports.confirmationStartingText = "Are you sure you want to assign all the child employees "
exports.confirmationFromText = "directly"

exports.roleEndpoint = "bx_block_roles_permissions/roles"
// Customizable Area End
