Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";

exports.libraryTitle = "My Library";
exports.btnExampleTitle = "CLICK ME";
exports.filterByData = "Filter By Date";
exports.filterMenuList = ["Today","Yesterday","Last 15 days"];
exports.tablist = ["Procedures", "Product Info", "Forms", "Checklists", "Miscellaneous Docs"];
exports.today = "Today";
exports.yesterday = "Yesterday";
exports.lastDays = "Last 15 days";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.getProcedureListApiEndPoint= "bx_block_cftabletview/procedures/?";
exports.checkListApiEndPoint = "bx_block_multipageforms2/checklists/?";
exports.deletecheckListApiEndPoint = "bx_block_multipageforms2/checklists/";
exports.departSelect = "Select Department";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.noDepartmentMessage = "No Department Found";
exports.date = "Date-";
exports.duplicate = "Duplicate";
exports.delete = "Delete";
exports.view = "View";
exports.edit = "Edit";
exports.fill = "Fill";
exports.create = "Create";
exports.favouriteMark = "Mark as Favourite";
exports.favouriteUnmark = "Unmark as Favourite";
exports.departSelect = "Select Department";
exports.deleteProcedureApiEndPoint = "bx_block_cftabletview/procedures/"
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.headerList = ["Procedures", "Procedure Name", "", "Date", "Action", "Favourite"];
exports.productHeaderList = ["Product Info", "Product Info Name", "Template Type", "Date", "Action", "Favourite"];
exports.menuHeaderList = ["Menu Templates", "Menu Template Name", "Template Type", "Date", "Action", "Favourite"];
exports.checkListHeaderList = ["Checklists", "Checklist Name", "", "Date", "Action", "Favourite"];
exports.formHeaderList = ["Forms", "Form Name", "", "Date", "Action", "Favourite"];

exports.title = "Currently No Procedure Found";

exports.from = "From";
exports.to = "To";
exports.TemplateNavigation = "/Template";
exports.favouriteProcedureApiEndPoint = "bx_block_cftabletview/procedures/";
exports.deleteModalTxt = ["Are you sure you want to delete ","This Procedure Template"," from the library?"]
exports.templateDeleteTitle = "Procedure";
exports.procedureFrom = "from library";
exports.yes = "yes";
exports.no = "no";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.FormApiEndPoint = "bx_block_multipageforms2/forms/?";
exports.deleteFormApiEndPoint = "bx_block_multipageforms2/forms/";
exports.formDeleteTitle = "Form";
exports.checklistDeleteTitle = "Checklist";
exports.checklistNoDataTxt = "Currently No Checklist Found";
exports.checklistFrom = "from library";
exports.fromLibrary = "from library";
exports.formNoDataTxt = "Currently No Form Found";
exports.productTemplateText = "Product Info Templates";
exports.menuTemplateText = "Menu Templates"

exports.noProductTitleText = "Currently No Product Template Found";
exports.searchResult = "No search result found for";
exports.productTemplateDeleteTitle = "Product Template";

exports.getMethod='GET'
exports.postMethod='POST'
exports.productTemplateListApiEndPoint= "bx_block_cftemplatebuilder6/product_infos/?";
exports.productTempApiEndPoint= "bx_block_cftemplatebuilder6/product_infos/";
exports.departError = "Please Add Department";
exports.validationApiContentType = "application/json";

exports.deleteSuccessMessage = "Product has been deleted successfully"
exports.productTemplateNavigation = "/ProductTemplate";

exports.menuTemplateListApiEndPoint= "bx_block_cftemplatebuilder6/menus/?";
exports.menuApiEndPoint= "bx_block_cftemplatebuilder6/menus/";

exports.menuTemplateDeleteTitle = "Menu Template";
exports.menuTemplateNavigation = "/MenuTemplate";

exports.noMenuText = "Currently No Menu Template Found";
exports.clearAll = "Clear All Filter";
exports.deleteMenuSuccessMessage = "Menu Template has been deleted successfully"
exports.procedureDeleteMessage = "Procedure deleted successfully";
exports.formDeleteMessage = "Form deleted successfully";
exports.checklistDeleteMessage = "Checklist deleted successfully";
exports.removeFavorite = "/remove_from_favorite";
exports.addFavorite = "/favorite";

exports.noDocTitle = "Currently No Documents Found";
exports.docDeleteTitle = "Document";
exports.documentFrom = "from procedure";
exports.miscDocHeaderList = ["Miscellaneous Doc", "Miscellaneous Doc Name", "", "", "Date", "Action"];
exports.getMiscellaneousDocListApiEndPoint = "bx_block_cftabletview/get_all_procedure_documents?"
exports.deleteMiscellaneousDocApiEndPoint = "bx_block_cftabletview/delete_procedure_document/"
exports.deleteDocumentSuccessMsg = "Document deleted successfully";
exports.downloadDocumentErrorMsg = "Error in download document";
exports.downloadDocumentName = 'Miscellaneous-Document';
exports.noSearchResult = "No search result found for"
// Customizable Area End